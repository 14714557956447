var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "user-info"
  }, [_c('div', {
    staticClass: "integral"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("积分总额")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.$store.state.integralCurrency.integral))])]), _vm.$store.state.integralCurrency.early_integral ? _c('div', {
    staticClass: "tip"
  }, [_c('img', {
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-1.png"
    }
  }), _c('div', [_vm._v("将有 " + _vm._s(_vm.$store.state.integralCurrency.early_integral.integral) + "积分 在 " + _vm._s(_vm.$store.state.integralCurrency.early_integral.expiredTime) + " 到期请尽快兑换使用")])]) : _vm._e()]), _c('div', {
    staticClass: "record-div"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("积分记录")]), _c('parent-list-content', {
    ref: "parentListContent",
    on: {
      "onDataLoad": _vm.onDataLoad,
      "pushData": _vm.pushData
    }
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "li"
    }, [_c('div', {
      staticClass: "left"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.remark) + " ")]), _c('div', {
      staticClass: "time"
    }, [_vm._v(_vm._s(item.createTime))])]), _c('div', {
      staticClass: "right"
    }, [_c('div', {
      staticClass: "value",
      class: {
        'add': item.type == 0
      }
    }, [_vm._v(_vm._s((item.type == 0 ? '+' : '-') + item.integral))])])]);
  }), 0)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };